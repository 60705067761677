import useSWR from 'swr'
import { useMemo } from 'react'

import { endpoints, fetcher } from 'src/utils/axios'
import { swrOpts } from 'src/providers/swr'

import { EnvironmentVariables } from '../../../models/utils/UtilsInterface'

// ----------------------------------------------------------------------

interface UseFetchEnvironmentVariablesResult {
  publicVariables: EnvironmentVariables
  publicVariablesLoading: boolean
  publicVariablesError: boolean // Replace `any` with the specific error type if you know it
  publicVariablesValidating: boolean
  publicVariablesEmpty: boolean
  publicVariablesErrorMessage?: string
}

export function useFetchEnvironmentVariables(): UseFetchEnvironmentVariablesResult {
  const URL = endpoints.utils.fetchPublicEnvironmentVariables

  // we have to pass the fetcher and opts in here because the function
  // gets called outside of the SWRProvider (in AuthProvider)
  const { data, error, isLoading, isValidating } = useSWR([URL, {}], fetcher, swrOpts)

  const memoizedValue = useMemo<UseFetchEnvironmentVariablesResult>(
    () => ({
      publicVariables: data?.variables,
      publicVariablesLoading: isLoading,
      publicVariablesError: error,
      publicVariablesErrorMessage: error?.message,

      publicVariablesValidating: isValidating,
      publicVariablesEmpty: !isLoading && !data?.variables,
    }),
    [data?.variables, error, isLoading, isValidating]
  )

  return memoizedValue
}

export type CustomError = {
  statusCode: number
  error: string
}

export type StandardSWRResponse = {
  isLoading: boolean
  isError: boolean
  isValidating: boolean
}
